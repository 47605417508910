import { graphql } from "gatsby";
import { Layout, Menu } from "antd";
import React, { Component } from "react";

import { Link } from "gatsby";
import "../css/rahul-styles.css";
import "../css/style.css";
import "../css/sharan-style.css";
import { Helmet } from "react-helmet";

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import ReactGA from "react-ga";
import PopupForm from "../components/popupForm";
import MainLayout from "../components/layout";

import placeholder from "../images/placeholder.png";
import ArrowRightSolidIcon from '../images/icons/arrow-right-solid.svg';
import ArrowLeftSolidIcon from '../images/icons/arrow-left-solid.svg';

let selectedCategoryName = "All Blogs";

class BlogListing extends Component {
	constructor(props) {
		super(props);
		this.state = {
			blogData: this.props.data.allWordpressWpBlog.edges,
			blogTop: this.props.data.allWordpressPage.nodes[0],
			collapsed: false,
			time: 900000000,
		};
	}

	componentDidMount() {
		const urlParams = new URLSearchParams(window.location.search);
		const searchQuery = urlParams.get("search") || "";
		
	
		const inputSearch = (this.state.searchTerm || "").trim();
	  
	
		if (!searchQuery && !inputSearch) {
		  this.setState({ blogData: [] });
		} else {
	
		  this.setState({ searchTerm: searchQuery || inputSearch }, () => {
			this.filterBlogs(searchQuery || inputSearch);
		  });
		}
	  }


	filterBlogs(searchTerm) {
		const filteredBlogs = this.props.data.allWordpressWpBlog.edges.filter(({ node }) => {

			const searchLower = searchTerm.toLowerCase();
			const matchesTitle = node.title.toLowerCase().includes(searchLower);
			const matchesImage = node.acf.blog_grid_image && node.acf.blog_grid_image.source_url.toLowerCase().includes(searchLower);
			const matchesContent = node.acf.content && node.acf.content.toLowerCase().includes(searchLower);
			const matchesSelectCategory = Array.isArray(node.acf.select_category) && node.acf.select_category.some(category => 
				category.toLowerCase().includes(searchLower)
			);
			
	
			return matchesTitle || matchesImage || matchesContent || matchesSelectCategory ;
		});
	

		this.setState({ blogData: filteredBlogs, searchTerm });
	}

handleSearchInput = (e) => {
    const searchTerm = e.target.value;
    this.setState({ searchQuery: searchTerm }, () => {
        if (searchTerm) {
            this.filterBlogs(searchTerm);
        } else {

            this.setState({ blogData: this.props.data.allWordpressWpBlog.edges });
        }
    });
};
  
	  
	handlePopupWindow = () => {
		this.setState({ time: Math.random() });
	};

	render() {
		const { Sider } = Layout;
		const post = this.state.blogTop;
		const metatitle = post.acf.meta_title;
		const metadescription = post.acf.meta_description;
		const focus_keyphrase = post.acf.focus_keyphrase;
		const blogTopID = this.props.data.allWordpressPage.nodes.flatMap(node => node.acf.top_articles.map(article => article.wordpress_id));
		// Get the array of edges from the GraphQL query result
const edges = this.props.data.allWordpressWpBlog.edges;

// Iterate over each edge and log the formatted output
edges.forEach((edge, index) => {
  const node = edge.node;
  //console.log(`Blog ${index + 1} ${node.date} - ${node.title}`);
});


		const blogTopNews = this.props.data.allWordpressWpBlog.edges.filter(edge => {
			return blogTopID.includes(edge.node.wordpress_id);
		}).map(edge => edge.node);


		const canonical_url = post.acf.canonical_url;
		//console.log("this.state.blogTop.acf.top_articles->"+JSON.stringify(blogTopNews));
		return (
			<div >
				<MainLayout>
					<Helmet>
						<title>{metatitle}</title>
						<meta name="description" content={metadescription} />
						{(() => { if (post.acf.focus_keyphrase != '' && post.acf.focus_keyphrase != null) { return (<meta name="keywords" content={focus_keyphrase} />) } })()}
						{(() => { if (post.acf.canonical_url != '' && post.acf.canonical_url != null) { return (<link rel="canonical" href={canonical_url} />) } })()}
						{(() => { if (post.acf.dynamic_schema != '' && post.acf.dynamic_schema != null) { return (<script type="application/ld+json">{JSON.stringify(JSON.parse(post.acf.dynamic_schema))}</script>) } })()}
						<link rel="icon" href="https://res.cloudinary.com/worldgrad/image/upload/v1612961357/worldgrad/twg_favicon_ymgrbw.png" />
					</Helmet>
					{/* <Header /> */}
					{/* Main Code */}
					<div class="newStyle">
						<div className="container">
							<ol class="transparentbreadcrumb breadcrumb breadcrumb-new">
								<li class="breadcrumb-item">
									<a href="/">Home </a>
								</li>
								<li class="breadcrumb-item active"> Study Resources</li>
							</ol>
						</div>
					</div>
					<div className="newStyle">
					<section 
          className="wow animate__fadeIn bg-light-gray padding-125px-tb sm-padding-80px-tb position-relative " 
          style={{
            backgroundImage: "url(https://assets.theworldgrad.com/gatsby-assets/images/Course-finder-banner-NewDesktop.jpg)",
            backgroundSize: "cover",
            backgroundPosition: "top"
          }}>
          <div className="opacity-medium-2 bg-medium-slate-blue"></div>
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-xl-6 col-lg-7 col-md-8 position-relative text-center d-flex justify-content-center flex-column">
			  <h1 className="text-white alt-font font-weight-500 letter-spacing-minus-1px line-height-50 sm-line-height-45 xs-line-height-30 no-margin-bottom">
    {this.state.searchTerm
        ? `Search Results for "${this.state.searchTerm}"`
        : "Search Results"}
</h1>
              </div>
            </div>
          </div>
        </section>
		

		<section className="half-section blog-left-side-bar position-relative overflow-visible pb-0">
		<div className="course-search-box-holder">
        <form className="course-search-box" onSubmit={(e) => e.preventDefault()}>
            <input
                type="text"
                id="lname"
                name="lname"
                className="programSortBySalect programvalues"
                placeholder="Search resources for studying abroad.."
                value={this.state.searchQuery || ""}
                onChange={this.handleSearchInput}
            />
            <input type="submit" value="" />
        </form>
    </div>
      </section>

	  </div>
					<div className="container" style={{ overflowX: "hidden", marginTop: "-30px" }}>
						<PopupForm time={this.state.time} />
						<div className="col-12">
							<button
								className="btn fixed-enquire-button"
								onClick={() => {
									this.handlePopupWindow();
								}}>
								Enquire
							</button>
						</div>

			
						<div className="container bottom-row-blog-listing-container mb-5">
							<div className="row bottom-row-blog-listing">
								<div className="col-12 p-0">
								<a 
    className="pagination-link next-page-link ml-auto d-inline-block mb-4"
   href="/study-resources/"
>
    <img 
        src={ArrowLeftSolidIcon} 
        alt="->" 
        className="right-icon newicon newicon-black left-5px" 
    /> 
    Go to All Study Resources
</a>
								<div className="card all-blog-main-card w-100 mt-10">
									<div className="card-with-side-menuh">
										
				
											<div className="col blog-listing-sidebar-container">
				
												<div className="sidebar-container">
					
	
										
															<div className="col-md-12">
		

																<div className="blog-listing-card-sub-heading-container">
													
	

																	<div className="blog-listing-masonry-layout">

																	<div className="blog-listing-wrapper">
  {this.state.blogData.length > 0 ? (
    this.state.blogData.map(({ node }) => {
      return (
        <div key={node.id} className="blog-listing-card-holder">
          <div className="card main-card-blog-listing mb-3">
            <div className="blog-image">
              {node.acf.blog_grid_image != null ? (
                <Link to={`/study-resources/${node.slug}/`}>
                  <img
                    src={node.acf.blog_grid_image.source_url}
                    className="rounded img-fluid blog-listing-card-image"
                    alt={node.title}
                  />
                </Link>
              ) : (
                <Link to={`/study-resources/${node.slug}/`}>
                  <img
                    src={placeholder}
                    className="rounded img-fluid blog-listing-card-image"
                    alt={node.title}
                  />
                </Link>
              )}
            </div>
            <div
              onClick={() => {
                ReactGA.event({
                  category: "Study Resources",
                  action: "Clicks",
                  label: `${ReactHtmlParser(node.title)}`,
                });
              }}
              className="card-body px-0 py-3 blog-listing-card-body"
            >
              <div className="category-holder">
                {node.acf.select_category.map((d, index) => (
                  <span key={`${d}-${index}`} className="blog-listing-category">
                    {d}
                  </span>
                ))}
              </div>
              <h5 className="card-title blog-listing-card-title blogtitle">
                <Link to={`/study-resources/${node.slug}/`}>
                  {ReactHtmlParser(node.title)}
                </Link>
              </h5>
              <p className="card-text blog-listing-card-text blogcontent">
                {ReactHtmlParser(node.acf.description)}
              </p>
              <p className="text-muted blog-listing-date-text"> - Posted on {node.date} </p>
            </div>
          </div>
        </div>
      );
    })
  ) : (
    <div className="no-results-message">
      <p>No results found for your search</p>
    </div>
  )}
</div>


																	</div>
																</div>
															</div>
								
			
												</div>
											</div>
										
									</div>
								</div>
								</div>
							</div>
						</div>
					</div>
					{/* <Footer /> */}
				</MainLayout>
			</div>
		);
	}
}

export default BlogListing;

export const bloglisting = graphql`
	query bloglisting {
		allWordpressWpBlog(sort: { order: DESC, fields: date } filter: { blog_categories: { ne: 21 } }) {
			edges {
				node {
					id
					title
					slug
					blog_categories
					wordpress_id
					date(formatString: "DD-M-YYYY")
					acf {
						description
						content
						select_category
						blog_grid_image { 
							source_url
						  }
					}
					featured_media {
						source_url
					}
				}
			}
		}


		allWordpressWpBlogCategories {
			edges {
				node {
					id
					name
					wordpress_id
					slug
				}
			}
		}

		allWordpressPage(filter: { wordpress_id: { in: 3241 } }) {
			nodes {
				title
				content
				acf {
					top_articles {
						wordpress_id
						post_title
						post_name
						post_date(formatString: "DD-M-YYYY")
					}
meta_title
meta_description
focus_keyphrase
canonical_url
dynamic_schema
					blog_categories {
						name
						slug
						term_id
					}
				}
			}
		}
	}
`;

// select_category below posted_date
